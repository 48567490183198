import React from "react"
import { graphql } from "gatsby"
import { StaticImage}  from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'

const HeroImg= styled('div')`
  ${tw`lg:block md:hidden`}
    margin-bottom: 50px;
`

const Title = styled('div')`
${tw`font-bold leading-normal leading-8 tracking-widest text-center mx-auto mb-4`}
`


const R = styled('div')`
    ${tw`flex my-2 p-2 mx-auto`}

    justify-content: space-between;
    align-items: center;
    max-width: 600px;
`


const PriceSection = styled('div')`
${tw` mb-16`}

  ${R}:nth-child(even) {
    ${tw`flex my-2 p-2 mx-auto bg-gray-300`}

  }
`

const D = styled('div')``
const P = styled('div')`
  ${tw`min-w-max ml-2`}

  text-align: right;
`

const MainTitle = styled('h1')`
  ${tw`font-bold leading-normal leading-8 tracking-widest text-center mx-auto mb-8  text-2xl`}
`

const PricingTemplate:React.FC = ({data}) => {
  const priceList = data.allCennikCsv.edges.reduce((r,a)=>{
    r[a.node.KATEGORIA] = r[a.node.KATEGORIA] || [];
    r[a.node.KATEGORIA].push(a.node);
    return r;
  },[]);
  return (
  <Layout data={data}
  prop={{
    canonicalUrl: 'https://www.mediroot.pl/cennik/',
    title: 'Cennik | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}
  >
    <HeroImg>
      <StaticImage
        src="../assets/images/hero2.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </HeroImg>

    <MainTitle>Cennik usług stomatologicznych</MainTitle>

    {Object.keys(priceList).map((group, i) => (
      <PriceSection key={`${group} ${i}`}>
      <Title>{group}</Title>
        {
          priceList[group].map((item, ii)=>(
            <R key={`${item} ${ii}`}>
              <D>
              {item['NAZWA']}
              </D>
              <P>
              {item['CENA']}
              </P>
          </R>
          ))
        }
      </PriceSection>
            ))}
  </Layout>);
};

export default PricingTemplate;


export const query = graphql`
  query {
    allCennikCsv {
      edges {
        node {
          CENA
          KATEGORIA
          NAZWA
          UWAGI
        }
      }
    }
  }
  `
